import React, {
  type Dispatch,
  PropsWithChildren,
  Reducer,
  useLayoutEffect,
} from 'react';
import dialogPolyfill from 'dialog-polyfill';
import classNames from 'classnames';

// TODO: move this one to src/modules/ and split it into multiple files

export enum Id {
  EquipmentRanking = 'equipment_ranking_modal',
  DepartmentsRanking = 'departments_ranking_modal',
  Forecasts = 'forecasts_modal',
  AboutUs = 'about_us_modal',
  Faq = 'faq_modal',
  Timeline = 'timeline_modal',
  PrevisionsLegende = 'previsions_legende_modal',
  PrevisionsGlobales = 'previsions_globales_modal',
  Cadeau = 'cadeau_modal',
  Resultats = 'resultats_modal',
  CreationContact = 'creation_contact_modal',
}

declare global {
  interface Window {
    // This stops typescript from complaining about the dialog ID not existing on the window object
    // It also limits the chance of use accidentally using an invalid ID
    [Id.DepartmentsRanking]?: HTMLDialogElement;
    [Id.EquipmentRanking]?: HTMLDialogElement;
    [Id.Forecasts]?: HTMLDialogElement;
    [Id.AboutUs]?: HTMLDialogElement;
    [Id.Faq]?: HTMLDialogElement;
    [Id.Timeline]?: HTMLDialogElement;
    [Id.PrevisionsLegende]?: HTMLDialogElement;
    [Id.PrevisionsGlobales]?: HTMLDialogElement;
    [Id.Cadeau]?: HTMLDialogElement;
    [Id.Resultats]?: HTMLDialogElement;
    [Id.CreationContact]?: HTMLDialogElement;
  }
}

type ModalContext = {
  state: State;
  dispatch: Dispatch<Action>;
};

export const Context = React.createContext<ModalContext>({
  state: {
    queue: [],
  },
  dispatch: () => () => {
    console.error(
      '[Dialog] No provider found for ModalContext, please wrap your application with one.'
    );
  },
});

type Action =
  | {
      type: 'open';
      id: Id;
      next?: Id;
    }
  | {
      type: 'close';
      id: Id;
    }
  | {
      type: 'open_next_in_queue';
    }
  | {
      type: 'clear_queue';
    };

type State = {
  queue: Id[];
};

function logMiddleware(reducer: Reducer<State, Action>) {
  return (state: State, action: Action) => {
    console.debug('[Dialog] Handling', action, state);
    const nextState = reducer(state, action);
    console.debug('[Dialog] Handled', action, nextState);
    return nextState;
  };
}

const hashes: Partial<Record<Id, string>> = {
  [Id.Resultats]: '#resultats',
  [Id.Faq]: '#faq',
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'open':
      openSafely(action.id);
      setHash(action.id);
      return {
        queue: action.next ? [...state.queue, action.next] : state.queue,
      };

    case 'close':
      closeSafely(action.id);
      clearHash();
      return state;

    case 'open_next_in_queue':
      const [next, ...rest] = state.queue;
      openSafely(next);
      return {
        queue: rest,
      };

    case 'clear_queue':
      clearHash();
      return {
        queue: [],
      };
  }
}

function openSafely(id: Id | null | undefined) {
  if (!id) {
    return;
  }
  window[id]?.showModal();
}

function closeSafely(id: Id | null | undefined) {
  if (!id) {
    return;
  }
  window[id]?.close();
}

function setHash(id: Id) {
  const hash = hashes[id] ?? null;
  if (hash) {
    window.location.hash = hash;
  }
}

function clearHash() {
  window.location.hash = ''; // single space instead of empty string to not have a trailing '#'
}

export function Provider({ children }: PropsWithChildren<{}>) {
  const [state, dispatch] = React.useReducer(logMiddleware(reducer), {
    queue: [],
  });

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
}

type UseModalType = {
  open(id: Id, next?: Id): void;
  // TODO: rendre l'ID optionnel et fermer toutes les modales ouvertes ?
  close(id: Id): void;
  openNextInQueue(): void;
  clearQueue(): void;
};

export function useModal(): UseModalType {
  const { dispatch } = React.useContext(Context);

  return {
    open: (id: Id, next?: Id) => dispatch({ type: 'open', id, next }),
    close: (id: Id) => dispatch({ type: 'close', id }),
    openNextInQueue: () => dispatch({ type: 'open_next_in_queue' }),
    clearQueue: () => dispatch({ type: 'clear_queue' }),
  };
}

type ModalProps = PropsWithChildren<{
  id: Id;
}>;

export function Dialog({ id, children }: ModalProps) {
  return (
    <div>
      <InnerDialog id={id}>{children}</InnerDialog>

      <Polyfill id={id} />
    </div>
  );
}

function InnerDialog({ id, children }: ModalProps) {
  console.debug('[Dialog] Rendering', { id });
  return (
    // "fixed" is required for the polyfill to work, see https://github.com/GoogleChrome/dialog-polyfill#stacking-context
    <dialog id={id} className={classNames('fixed tw-modal')}>
      {children}
    </dialog>
  );
}

export function Box({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={classNames('tw-modal-box tw-relative', className)}>
      {children}
    </div>
  );
}

export function CloseButton({ className }: { className?: string }) {
  const { clearQueue } = useModal();
  return (
    <button
      onClick={clearQueue}
      className={classNames(
        'tw-btn tw-btn-sm tw-btn-circle tw-btn-ghost tw-bg-white tw-absolute tw-right-2 tw-top-2',
        className
      )}
    >
      ✕
    </button>
  );
}

export function Overlay() {
  const { clearQueue } = useModal();
  return (
    <form method="dialog" className="tw-modal-backdrop">
      <button onClick={clearQueue}>Close</button>
    </form>
  );
}

// See https://github.com/GoogleChrome/dialog-polyfill
function Polyfill({ id }: { id: Id }) {
  useLayoutEffect(() => {
    const dialog = document.querySelector<HTMLDialogElement>('#' + id);

    console.debug('[Dialog] Adding polyfill', {
      id,
      dialog: dialog ? 'found' : 'not found',
    });

    if (dialog) {
      dialogPolyfill.registerDialog(dialog);
    }
  });
  return null;
}
