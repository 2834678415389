import React from 'react';
import { Form } from 'react-bootstrap';
import Label from '../Label';

const TextareaInput = ({ input, meta, label, required, ...props }) => {
  const showError = (meta.touched && meta.error) || meta.submitError;
  const error = meta.error ?? meta.submitError;

  return (
    <Form.Group>
      <Label label={label} required={required} />
      <Form.Control
        value={input.value}
        onChange={input.onChange}
        as="textarea"
        isInvalid={showError}
        {...props}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default TextareaInput;
