import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

type ButtonProps = PropsWithChildren<{
  onClick?: () => void;
  title?: string | null;
  className?: string;
  variant?: Variant;
  size?: Size;
  shouldScale?: boolean; // si true le bouton change de taille en fonction du viewport
}>;

type Variant = 'primary' | 'neutral' | 'accent';
type Size = 'lg' | 'md' | 'sm' | 'xs';

export function MapButton({
  children,
  onClick,
  title,
  className,
  variant = 'neutral',
  size = 'md',
  shouldScale = false,
}: ButtonProps) {
  return (
    <button
      className={cn(
        'tw-btn tw-text-sm xl:tw-text-md',
        'tw-border tw-border-solid tw-border-base-300 hover:tw-border hover:tw-border-base-300',
        {
          'tw-btn-neutral': variant === 'neutral',
          'tw-btn-primary': variant === 'primary',
          'tw-btn-accent': variant === 'accent',
        },
        {
          'tw-btn-xs': !shouldScale && size === 'xs',
          'tw-btn-sm': !shouldScale && size === 'sm',
          'tw-btn-md': !shouldScale && size === 'md',
          'tw-btn-lg': !shouldScale && size === 'lg',
        },
        {
          'tw-btn-xs sm:tw-btn-sm': shouldScale && size === 'sm',
          'tw-btn-sm md:tw-btn-md ': shouldScale && size === 'md',
          'tw-btn-md lg:tw-btn-lg': shouldScale && size === 'lg',
        },
        className
      )}
      onClick={onClick}
      title={title || undefined}
    >
      {children}
    </button>
  );
}
