import React from 'react';
import { reduxForm } from 'redux-form';
import NavigationBar from '../../NavigationBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import SectionTitle from '../../SectionTitle';
import styles from './styles.module.scss';
import validate from './validate';
import { EmailField, PhoneField } from '../fields';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { useConfig } from '../../../../../modules/config';

const UserForm = ({ handleSubmit, previousStep, onSubmit }) => {
  const { t } = useTranslation();
  const { tos } = useConfig();
  return (
    <section>
      <SectionTitle title={t('form_user.title')} />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Row>
          <Col xs={12} md={6}>
            <EmailField />
          </Col>
          <Col xs={12} md={6}>
            <PhoneField />
          </Col>
          <Col xs={12}>
            <Alert variant="info">{t('form_user.alert')}</Alert>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Trans i18nKey="form_user.tos" t={t}>
              En cliquant sur Envoyer, vous acceptez nos
              <a
                href={tos.url}
                target="_blank"
                rel="noreferrer"
                className="tw-text-primary"
              >
                {t('form_user.tos_link')}
              </a>
              .
            </Trans>
          </Col>
        </Row>
        <div className={styles.navigationWrapper}>
          <NavigationBar
            previousStep={previousStep}
            shouldSubmit={true}
            submitLabel={t('common.submit')}
          />
        </div>
      </form>
    </section>
  );
};

export default withTranslation()(
  reduxForm({
    form: 'observation',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(UserForm)
);
