import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import LatLng from '../../../../models/latLng';
import TownInput from './index';
import { getCityLabel, searchCity } from '../../../../api/city';

class TownInputContainer extends Component {
  handleChange = (option) => {
    const { input, formName, dispatch } = this.props;

    const coordinates = new LatLng({
      longitude: option.value.coordinates.longitude,
      latitude: option.value.coordinates.latitude,
    });

    dispatch(change(formName, 'coordinates', coordinates));
    dispatch(change(formName, 'town', option.value['@id']));
    input.onChange(option);
  };

  mapOptionsToValues = (options) => {
    if (this.props.mapOptionsToValues) {
      return this.props.mapOptionsToValues(options);
    }

    return options.map((option) => ({
      value: option,
      label: getCityLabel(option),
    }));
  };

  loadOptions = async (inputValue) => {
    if (!inputValue || inputValue.length < 3) {
      return [];
    }

    const data = await searchCity(inputValue);
    return this.mapOptionsToValues(data['hydra:member']);
  };

  render() {
    const { props } = this;

    return (
      <TownInput
        value={props.input.value}
        label={props.label}
        loadOptions={this.loadOptions}
        onBlur={(event) => event.preventDefault()}
        onChange={this.handleChange}
        props={props}
        placeholder={props.placeholder}
        required={props.required}
        meta={props.meta}
      />
    );
  }
}

export default connect()(TownInputContainer);
