import React, { PropsWithChildren } from 'react';
import { MapContainer, Pane, TileLayer, useMapEvents } from 'react-leaflet';
import { useDispatch } from 'react-redux';

import { closeObservationDetailsModalAction } from '../../actions/ui/modal/observation/details';
import { buildFromLeafletMap } from '../../models/location';
import { setLocationAction } from '../../actions/location';
import { PaneName, ZIndex } from '../../config';
import { useConfig } from '../../modules/config';

/*
 * IMPORTANT NOTE: pour des raisons de performance, il est important de noter que les layers ne se render qu'une seule fois.
 * Si vous voulez les re-render, changer leurs keys. Cela est le comportement attendu de react-leaftlet.
 */
export function MapLayer({ children }: PropsWithChildren) {
  const config = useConfig();

  return (
    <MapContainer
      className={'tw-h-screen tw-w-screen tw-overflow-hidden'}
      center={{
        lat: config.map.position.latitude ?? undefined,
        lng: config.map.position.longitude ?? undefined,
      }}
      fadeAnimation={true}
      zoom={7}
      zoomControl={false}
      maxBounds={config.map.bounds}
      maxZoom={14}
      minZoom={6}
      preferCanvas={true}
    >
      {/* START - Event handlers */}
      <ModalDismisser />
      <LocationUpdater />
      {/* END - Event handlers */}

      <TileLayer
        url={config.map.layers.noLabels}
        attribution={config.map.attribution}
      />
      {config.map.layers.labels && (
        <Pane name={PaneName.Labels} style={{ zIndex: ZIndex.Labels }}>
          <TileLayer
            url={config.map.layers.labels}
            attribution={config.map.attribution}
          />
        </Pane>
      )}
      {children}
    </MapContainer>
  );
}

function LocationUpdater() {
  const dispatch = useDispatch();

  const updateLocation = (event: any) => {
    const location = buildFromLeafletMap(event.target);
    // @ts-ignore
    dispatch(setLocationAction(location));
  };

  useMapEvents({
    moveend: updateLocation,
    zoomend: updateLocation,
  });

  return null;
}

function ModalDismisser() {
  const dispatch = useDispatch();

  useMapEvents({
    click: () => {
      dispatch(closeObservationDetailsModalAction());
    },
  });

  return null;
}
