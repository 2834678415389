import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

import logoCereapro from '../../images/partners/logo-cereapro.png';
import logoTerreNet from '../../images/partners/logo-terre-net.png';
import logoMaterielAgricole from '../../images/partners/logo-materiel-agricole-info.png';
import logoFarmr from '../../images/partners/logo-farmr.png';

import * as Modal from '../../components/modals';
import { NavButton } from '../../components/nav/buttons';
import { Partner, useFaq, usePartners } from './hooks';
import { Spinner } from '../../components/common/Spinner';
import { useConfig } from '../config';

import styles from './styles.module.scss';
import { Id, useModal } from '../../components/modals';
import { HashMatcher } from '../routing';

export function AboutUsButton() {
  const { t } = useTranslation();
  const { open } = Modal.useModal();

  return (
    <NavButton
      onClick={() => open(Modal.Id.AboutUs)}
      icon={<FaInfoCircle />}
      title={t('modale_a_propos.title')}
    >
      {t('modale_a_propos.title')}
    </NavButton>
  );
}

export function FaqButton() {
  const { t } = useTranslation();
  const { open } = Modal.useModal();

  return (
    <NavButton
      onClick={() => open(Modal.Id.Faq)}
      icon={<FaInfoCircle />}
      title={t('modal_faq.title')}
    >
      {t('modal_faq.title')}
    </NavButton>
  );
}

export function AboutUsModal() {
  const { t } = useTranslation();

  return (
    <Modal.Dialog id={Modal.Id.AboutUs}>
      <Modal.Box className="tw-max-w-4xl tw-relative">
        <form method="dialog" className="tw-sticky tw-top-0 tw-left-0">
          <Modal.CloseButton />
        </form>

        <h3 className="tw-font-bold tw-text-xl tw-text-center tw-w-full">
          {t('modale_a_propos.header')}
        </h3>

        <PartnersBody />
      </Modal.Box>
      <Modal.Overlay />;
    </Modal.Dialog>
  );
}

export function FaqModal() {
  const { t } = useTranslation();
  const { data, isPending } = useFaq();
  const sections = data ? data['hydra:member'] ?? [] : [];
  const { tos } = useConfig();

  return (
    <Modal.Dialog id={Modal.Id.Faq}>
      <Modal.Box className="tw-max-w-4xl tw-relative">
        <form method="dialog" className="tw-sticky tw-top-0 tw-left-0">
          <Modal.CloseButton />
        </form>

        <h3 className="tw-font-bold tw-text-xl tw-text-center tw-w-full">
          {t('modal_faq.header')}
        </h3>

        <div className="tw-text-center">
          {tos.enabled && (
            <a
              href={tos.url}
              rel="noreferrer"
              target="_blank"
              className="tw-text-primary tw-block"
            >
              {t('modale_a_propos.tos')}
            </a>
          )}
        </div>

        {/* TODO: add it to the translation file instead of hardcoding it here*/}
        <hr />

        <div className="tw-prose tw-max-w-full">
          {sections.map((faq) => (
            <section key={faq.id}>
              <h4>{faq.title}</h4>
              {faq.content && (
                <div dangerouslySetInnerHTML={{ __html: faq.content }} />
              )}
            </section>
          ))}
        </div>

        {isPending && <Spinner />}
      </Modal.Box>
      <Modal.Overlay />
    </Modal.Dialog>
  );
}

function PartnersBody() {
  const { t } = useTranslation();
  const { aboutUs, tos } = useConfig();
  const { data, status } = usePartners();

  const partners = data ? data['hydra:member'] ?? [] : [];

  return (
    <div className="tw-mt-8">
      <div className="tw-text-center">
        {/* If the FAQ is enabled, the TOS are displayed in their own modal */}
        {tos.enabled && !tos.faqEnabled && (
          <a
            href={tos.url}
            rel="noreferrer"
            target="_blank"
            className="tw-text-primary tw-block"
          >
            {t('modale_a_propos.tos')}
          </a>
        )}

        <p className="tw-mt-4">
          <Trans i18nKey="modale_a_propos.join_us" t={t}>
            x
            <a
              href={`mailto:${aboutUs.contactEmail}`}
              target="_blank"
              rel="noreferrer"
              className="tw-text-primary"
            >
              {aboutUs.contactEmail}
            </a>
            y
          </Trans>
        </p>
      </div>

      <hr />

      {status === 'pending' && (
        <div className="tw-w-full tw-h-full tw-p-4">
          <Spinner />
        </div>
      )}

      {partners.map((partner) => {
        const src = partner.logoUrl ?? getPartnerLogo(partner);
        return (
          <div key={partner.id} className={styles.partnerWrapper}>
            <a href={partner.websiteUrl} target="_blank" rel="noreferrer">
              {src && (
                <img className={styles.image} src={src} alt={partner.name} />
              )}
            </a>
            <h5 className={styles.title}>{partner.name}</h5>
            {partner.description && (
              <p
                dangerouslySetInnerHTML={{
                  __html: partner.description,
                }}
              />
            )}
          </div>
        );
      })}

      <hr />

      <Attributions />
    </div>
  );
}

// Hardcoded logos for partners that don't have a logoUrl in the DB
function getPartnerLogo(partner: Partner): null | string {
  switch (partner.name) {
    case 'Cereapro':
      return logoCereapro;
    case 'Terre-Net':
      return logoTerreNet;
    case 'Farmr':
      return logoFarmr;
    case 'MatérielAgricole.info':
      return logoMaterielAgricole;
    default:
      return null;
  }
}

function Attributions() {
  const { t } = useTranslation();
  return (
    <div>
      <h5 className={styles.title}>{t('modale_a_propos.attribution.title')}</h5>
      <p>
        <a
          href="https://www.flaticon.com/free-icons/combine-harvester"
          title="combine harvester icons"
        >
          Combine harvester icons created by Freepik - Flaticon
        </a>
      </p>
    </div>
  );
}

export function FaqModalAutoOpener() {
  const { open } = useModal();
  return (
    <HashMatcher
      hash="#faq"
      onMatch={() => {
        open(Id.Faq);
      }}
    />
  );
}
