import { Record } from 'immutable';

type LatLngProps = { latitude: number | null; longitude: number | null };

export const LatLng = Record<LatLngProps>({
  latitude: null,
  longitude: null,
});

export default LatLng;
