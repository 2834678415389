import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactForm } from '../../components/contact/forms/ContactForm';
import * as Modal from '../../components/modals';

export function CreationContactModal() {
  const { t } = useTranslation();

  return (
    <Modal.Dialog id={Modal.Id.CreationContact}>
      <Modal.Box className="tw-max-w-3xl">
        <form method="dialog">
          <Modal.CloseButton />
        </form>

        <h3 className="tw-font-bold tw-text-lg tw-text-center tw-w-full">
          {t('modale_contact.title')}
        </h3>

        <div className="tw-flex tw-flex-col tw-gap-4 tw-p-5">
          <div role="alert" className="alert tw-bg-slate-100">
            <span>{t('modale_contact.description')}</span>
          </div>

          <ContactForm />
        </div>
      </Modal.Box>
      <Modal.Overlay />
    </Modal.Dialog>
  );
}
