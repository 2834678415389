import React from 'react';
import { Form } from 'react-bootstrap';
import Label from '../Label';

const SelectInput = ({ input, meta, label, choices, required }) => {
  const showError = (meta.touched && meta.error) || meta.submitError;
  const error = meta.error ?? meta.submitError;
  return (
    <Form.Group>
      <Label label={label} required={required} />
      <Form.Control
        value={input.value}
        onChange={input.onChange}
        as="select"
        isInvalid={showError}
      >
        <option value="" />
        {choices.map((choice) => (
          <option key={choice.value} value={choice.value}>
            {choice.label}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default SelectInput;
