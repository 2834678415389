import location from './location';
import askedPosition from './askedPosition';
import SensorDetailsModal from './ui/modal/sensor/details';
import PartnerDetailsModal from './ui/modal/partner/details';
import ObservationDetailsModal from './ui/modal/observation/details';
import toast from './ui/toast';
import observationCreationModal from './ui/modal/observation/creation';
import withinBoxModal from './ui/modal/observationsWithinBox';

export default {
  ...location,
  ...askedPosition,
  ...SensorDetailsModal,
  ...PartnerDetailsModal,
  ...ObservationDetailsModal,
  ...toast,
  ...observationCreationModal,
  ...withinBoxModal,
};
