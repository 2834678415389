import React from 'react';
import { FaTimes } from 'react-icons/fa';
import cx from 'classnames';
import { connect, useDispatch } from 'react-redux';
import Table from 'react-bootstrap/Table';
import logoCereapro from '../../../images/partners/logo-cereapro.png';
import logoTerreNet from '../../../images/partners/logo-terre-net.png';
import logoFarmr from '../../../images/partners/logo-farmr.png';
import logoMaterielAgricole from '../../../images/partners/logo-materiel-agricole-info.png';
import {
  getSelectedPartner,
  isPartnerDetailsModalOpen,
} from '../../../selectors/ui/modal/partner/details';
import { closePartnerDetailsModalAction } from '../../../actions/ui/modal/partner/details';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

// Hardcoded logos, the rest are in the database
function getPartnerLogo(partner) {
  const partnerName = partner.name;
  switch (partnerName) {
    case 'Cereapro':
      return logoCereapro;
    case 'Terre-Net':
      return logoTerreNet;
    case 'Farmr':
      return logoFarmr;
    case 'MatérielAgricole.info':
      return logoMaterielAgricole;
    default:
      return null;
  }
}

const PartnerDetailsModal = ({ partner, isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closePartnerDetailsModalAction());
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h5 className={cx(styles.header, getClassName(partner))}>
        <span className={styles.closeButton} onClick={handleClose}>
          <FaTimes />
        </span>
        {partner.name}
      </h5>
      <div className={styles.tableWrapper}>
        <Table striped bordered hover className={styles.table}>
          <tbody>
            <tr>
              <td>
                <a href={partner.websiteUrl} target="blank">
                  <img
                    className={styles.image}
                    src={partner.logoUrl ?? getPartnerLogo(partner.name)}
                    alt={partner.name}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: partner.description,
                    }}
                  />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href={partner.websiteUrl}
                  className={cx(
                    styles.button,
                    getClassName(partner),
                    'btn btn-secondary btn-block'
                  )}
                  target="blank"
                >
                  {t('modale_partenaires.go_to_website_button', {
                    name: partner.name,
                  })}
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default connect((state) => ({
  partner: getSelectedPartner(state),
  isOpen: isPartnerDetailsModalOpen(state),
}))(PartnerDetailsModal);

function getClassName(partner) {
  if (partner.name === 'MatérielAgricole.info') {
    return styles.MaterielAgricole;
  }

  if (partner.name === 'The Farming Forum') {
    return styles.TheFarmingForum;
  }

  return styles[partner.name];
}
