import React, { Fragment } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Label from '../Label';
import { FaTimes } from 'react-icons/fa';
import { FaCamera } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import styles from './styles.module.scss';
import { Spinner } from '../../Spinner';
import { useTranslation } from 'react-i18next';

const MediaImageInput = ({
  input,
  id,
  meta,
  label,
  required,
  onChange,
  onRemove,
  feedback,
  filePath,
  uploading,
}) => {
  const { t } = useTranslation();

  const showError = (meta.touched && meta.error) || meta.submitError;
  const error = meta.error ?? meta.submitError;

  return (
    <Form.Group>
      <Label label={label} required={required} />
      {uploading && (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      {!uploading && !filePath && (
        <InputGroup>
          <Form.Control
            type="text"
            id={id}
            name={id}
            value={input.value}
            isInvalid={showError}
            readOnly
            hidden
          />
          <Button className={styles.button} variant="secondary">
            <label className={styles.label} htmlFor="single">
              <FaCamera className="tw-text-xl" />
            </label>
            <input
              type="file"
              id="single"
              onChange={onChange}
              hidden
              accept="image/*"
            />
          </Button>
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          {feedback && <Form.Text className="text-muted">{feedback}</Form.Text>}
        </InputGroup>
      )}
      {!uploading && filePath && (
        <>
          <img src={filePath} className={styles.image} alt="uploaded_image" />
          <Button variant="link" onClick={onRemove} size="sm">
            <FaTimes className={styles.removeIcon} />
            <span>{t('form.media_image_input.supprimer_photo')}</span>
          </Button>
        </>
      )}
    </Form.Group>
  );
};

export default MediaImageInput;
