import { useCookies } from 'react-cookie';

export function usePlace() {
  const [cookies, setCookie] = useCookies(['place-2023']);

  function changePlace(newPlace) {
    setCookie('place-2023', newPlace);
  }

  function getPlace() {
    return cookies['place-2023'];
  }

  return {
    changePlace,
    getPlace,
  };
}
