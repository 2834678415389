import { createReducer } from 'redux-act';

import {
  openPartnerDetailsModalAction,
  closePartnerDetailsModalAction,
  selectPartnerAction,
} from '../../../../actions/ui/modal/partner/details';
import InitialState from '../../../../models/initialState';
import PartnerDetailsModal from '../../../../models/ui/modal/partner/details';

export const REDUCER_KEY = 'ui/modal/partner/details';

const reducer = createReducer(
  {
    [openPartnerDetailsModalAction]: (state) =>
      state.setIn(['data', 'isOpen'], true),
    [closePartnerDetailsModalAction]: (state) =>
      state.setIn(['data', 'isOpen'], false),
    [selectPartnerAction]: (state, payload) =>
      state.setIn(['data', 'partner'], payload),
  },
  new InitialState({
    data: new PartnerDetailsModal(),
  })
);

export default {
  [REDUCER_KEY]: reducer,
};
