import React from 'react';
import { Seq } from 'immutable';
import { useDispatch } from 'react-redux';
import L from 'leaflet';

import type { Sensor as SensorType } from './hooks';
import { useSensors } from './hooks';
import {
  openSensorDetailsModalAction,
  selectSensorAction,
} from '../../actions/ui/modal/sensor/details';
import { closeObservationDetailsModalAction } from '../../actions/ui/modal/observation/details';
import { closePartnerDetailsModalAction } from '../../actions/ui/modal/partner/details';
import Marker from '../../components/common/Marker';
import { useConfig } from '../config';

export function SensorsPane() {
  const { data } = useSensors();
  const {
    sensors: { amountOnMap },
  } = useConfig();
  const sensors = data ? data['hydra:member'] : [];

  // TODO: [REFACTO] do the sampling server side
  const sample = Seq(sensors)
    .map((x) => ({ x, r: Math.random() }))
    .sort((a, b) => a.r - b.r)
    .map((a) => a.x)
    .slice(0, amountOnMap)
    .toArray();

  return sample.map((sensor) => {
    return <Sensor sensor={sensor} key={sensor.id} />;
  });
}

type SensorProps = {
  sensor: SensorType;
};

// TODO: [REFACTO] use GeoJSON instead of marker, similar to what has been done with forecasts
function Sensor({ sensor }: SensorProps) {
  const dispatch = useDispatch();

  const handleClick = () => {
    // @ts-ignore
    dispatch(selectSensorAction(sensor));
    dispatch(closeObservationDetailsModalAction());
    dispatch(closePartnerDetailsModalAction());
    dispatch(openSensorDetailsModalAction());
  };

  const icon = new L.Icon({
    iconUrl:
      'https://res.cloudinary.com/comparateuragricole/image/upload/v1689182429/moisson-live/markers/weather-station.png',
    iconSize: [24, 28.1],
  });

  return (
    <Marker
      icon={icon}
      onClick={handleClick}
      latLng={sensor.coordinates}
      zIndexOffset={15}
    />
  );
}
