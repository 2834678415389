import { createReducer } from 'redux-act';
import { setLocationAction, setPositionAction } from '../actions/location';
import InitialState from '../models/initialState';
import LatLng from '../models/latLng';
import Bounds from '../models/bounds';
import Location from '../models/location';

export const REDUCER_KEY = 'location';

const defaultLocation = new Location({
  position: new LatLng(),
  bounds: new Bounds(),
});

const reducer = createReducer(
  {
    [setLocationAction]: (state, payload) => state.set('data', payload),
    [setPositionAction]: (state, payload) =>
      state.setIn(['data', 'position'], payload),
  },
  new InitialState({
    data: defaultLocation,
  })
);

const reducers = {
  [REDUCER_KEY]: reducer,
};

export default reducers;
