import { createReducer } from 'redux-act';

import {
  openSensorDetailsModalAction,
  closeSensorDetailsModalAction,
  selectSensorAction,
} from '../../../../actions/ui/modal/sensor/details';
import InitialState from '../../../../models/initialState';
import SensorDetailsModal from '../../../../models/ui/modal/sensor/details';

export const REDUCER_KEY = 'ui/modal/sensor/details';

const reducer = createReducer(
  {
    [openSensorDetailsModalAction]: (state) =>
      state.setIn(['data', 'isOpen'], true),
    [closeSensorDetailsModalAction]: (state) =>
      state.setIn(['data', 'isOpen'], false),
    [selectSensorAction]: (state, payload) =>
      state.setIn(['data', 'sensor'], payload),
  },
  new InitialState({
    data: new SensorDetailsModal(),
  })
);

export default {
  [REDUCER_KEY]: reducer,
};
