import 'core-js/es';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';

import App from './App';
import store from './store';
import sagas from './sagas';
import { sagaMiddleware } from './middleware';
import i18n from './i18n';

import 'bootstrap/dist/css/bootstrap.css';
import './styles/base.scss';
import { Provider } from 'react-redux';
import { getCountryCode } from './config';

const isDev = process.env.NODE_ENV === 'development';

if (!isDev) {
  Sentry.init({
    dsn: 'https://1e0934a1067d44988723af36467a1752@o190242.ingest.sentry.io/4505368930156544',
    release: process.env.PLATFORM_TREE_ID ?? 'unknown',
    environment:
      process.env.REACT_APP_SENTRY_ENV ?? process.env.NODE_ENV ?? 'production',
    integrations: [
      Sentry.browserTracingIntegration({}),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: isDev ? 1.0 : 0.1, // Capture 100% of the transactions, reduce in production!// Capture 100% of the transactions, reduce in production!
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/moisson-live\.com\/api/,
      /^https:\/\/harvest-live\.co\.uk\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: isDev ? 1.0 : 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: isDev ? 1.0 : 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = ReactDOM.createRoot(rootElement);

console.info(`[App] Starting app with config`, {
  country: getCountryCode(),
});

root.render(
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
      {/* Only loaded during if env is dev */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </I18nextProvider>
);

sagaMiddleware.run(sagas);
