import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Label from '../Label';
import { getCountryCode } from '../../../../config';

const lang = getCountryCode() === 'uk' ? 'en' : 'fr';

const FieldInput = ({
  input,
  id,
  meta,
  type,
  placeholder,
  min,
  max,
  label,
  append,
  prepend,
  required,
  autoComplete,
  step,
  inputMode,
}) => {
  const showError = (meta.touched && meta.error) || meta.submitError;
  const error = meta.error ?? meta.submitError;

  return (
    <Form.Group>
      <Label label={label} required={required} />
      <InputGroup>
        {prepend && (
          <InputGroup.Append>
            <InputGroup.Text id="inputGroupPrepend">{prepend}</InputGroup.Text>
          </InputGroup.Append>
        )}
        <Form.Control
          type={type}
          id={id}
          name={id}
          inputMode={inputMode}
          step={step}
          autoComplete={autoComplete}
          placeholder={placeholder}
          min={min}
          max={max}
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          isInvalid={showError}
          lang={lang}
        />
        {append && (
          <InputGroup.Append>
            <InputGroup.Text id="inputGroupAppend">{append}</InputGroup.Text>
          </InputGroup.Append>
        )}
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export default FieldInput;
