import React from 'react';

import styles from './styles.module.scss';

import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

const NavigationBar = ({
  previousStep,
  shouldSubmit,
  canSkip = false,
  submitLabel,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      {previousStep && (
        <Button
          variant="light"
          onClick={previousStep}
          className={styles.previousButton}
          size="lg"
        >
          {t('common.previous')}
        </Button>
      )}
      {shouldSubmit && (
        <Button
          size="lg"
          variant="success"
          type="submit"
          className={styles.nextButton}
        >
          {submitLabel}
        </Button>
      )}
      {canSkip && (
        <Button
          variant="light"
          type="submit"
          className={styles.skipButton}
          size="lg"
        >
          {t('common.skip')}
        </Button>
      )}
    </div>
  );
};

export default NavigationBar;
