import React from 'react';
import { Form } from 'react-bootstrap';
import Label from '../Label';
import cn from 'classnames';

const HorizontalChoiceInput = ({
  input,
  meta,
  label,
  required,
  choices = [],
}) => {
  const showError = (meta.touched && meta.error) || meta.submitError;
  const error = meta.error ?? meta.submitError;

  return (
    <Form.Group>
      <Label label={label} required={required} />
      <div>
        <ul className="tw-flex tw-items-stretch tw-w-full tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-divide-x">
          {choices.map(({ value, label }) => {
            const id = input.name + '-' + value;
            return (
              <li
                className="tw-w-full tw-border-b tw-border-gray-200 sm:tw-border-b-0"
                key={value}
              >
                <label
                  className="tw-flex tw-items-center tw-w-full tw-h-full tw-mb-0 tw-ps-3 tw-cursor-pointer"
                  htmlFor={id}
                >
                  <input
                    {...input}
                    type="radio"
                    id={id}
                    value={value}
                    className={cn([
                      'tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 focus:tw-ring-blue-500',
                      showError && 'tw-border-red-500',
                    ])}
                  />
                  <div className="tw-w-full tw-py-3 tw-ms-2 tw-text-xs tw-font-medium tw-text-gray-900">
                    {label}{' '}
                  </div>
                </label>
              </li>
            );
          })}
        </ul>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};

export default HorizontalChoiceInput;
