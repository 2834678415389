import React, { PropsWithChildren, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useMap } from 'react-leaflet';

type ItemProps = PropsWithChildren<{
  onClick: () => void;
  selected?: boolean;
}>;

export function Item({ selected, children, onClick }: ItemProps) {
  return (
    <li
      onClick={onClick}
      className={cn('tw-font-bold tw-text-sm tw-cursor-pointer')}
    >
      <button
        className={cn(
          'tw-min-h-10 tw-gap-x-3 !tw-px-2',
          'tw-btn tw-btn-sm tw-btn-neutral tw-w-full lg:tw-btn-md lg:tw-join-item lg:tw-no-animation',
          'tw-flex tw-items-center tw-justify-start tw-content-center lg:tw-items-center lg:tw-content-center'
        )}
      >
        {children}
      </button>
    </li>
  );
}

type SelectableItemProps = {
  onClick: () => void;
  isActive: boolean;
  label: string;
  image?: string | React.ReactNode;
  alt?: string;
  className?: string;
};

export function SelectableItem({
  className,
  label,
  alt,
  image,
  isActive,
  onClick,
}: SelectableItemProps) {
  return (
    <li
      onClick={onClick}
      className={cn(
        'tw-font-bold tw-text-sm tw-cursor-pointer',
        isActive ? 'tw-opacity-1' : 'tw-opacity-40',
        className
      )}
    >
      <button
        className={cn(
          'tw-min-h-10 tw-gap-x-3 !tw-px-2',
          'tw-btn tw-btn-sm tw-btn-neutral tw-w-full lg:tw-btn-md lg:tw-join-item lg:tw-no-animation',
          'tw-inline-flex tw-items-center tw-justify-start tw-content-center lg:tw-items-center lg:tw-content-center'
        )}
      >
        {typeof image === 'string' ? (
          <img
            className={'tw-w-7 tw-min-h-6 tw-h-auto'}
            src={image}
            alt={alt}
          />
        ) : image ? (
          image
        ) : (
          <div className="tw-w-7 tw-min-h-6 tw-h-auto" />
        )}
        <span>{label}</span>
      </button>
    </li>
  );
}

export function MenuTitle({ children }: PropsWithChildren) {
  return <li className="tw-menu-title">{children}</li>;
}

export function Separator() {
  return (
    <li className="tw-flex tw-flex-col tw-justify-center tw-align-center">
      <hr className="tw-my-0" />
    </li>
  );
}

type DropdownProps = PropsWithChildren<{
  className?: string;
}>;

export function Dropdown({ children, className }: DropdownProps) {
  const map = useMap();
  const detailsRef = useRef<null | HTMLDetailsElement>(null);

  // Idéalement, pour fermer le dropdown lorsque l'utilisateur clique en dehors du menu, nous devrions utiliser
  // la méthode 2 indiquée ici : https://daisyui.com/components/dropdown/#method-2-using-css-focus
  // Elle ne fonctionne pas et je n'arrive pas à savoir pourquoi, donc on le fait à la mano.
  useEffect(() => {
    function closeDropdown(event: L.LeafletMouseEvent) {
      const originalTarget = event.originalEvent.target;

      const isClickOutsideMenu =
        !(originalTarget instanceof HTMLElement) ||
        !detailsRef.current?.contains(originalTarget);

      if (isClickOutsideMenu) {
        detailsRef.current?.removeAttribute('open');
      }
    }

    map.on('click', closeDropdown);

    return () => {
      map.removeEventListener('click', closeDropdown);
    };
  }, [map]);

  return (
    <details
      className={cn('tw-dropdown tw-dropdown-bottom', className)}
      ref={detailsRef}
    >
      {children}
    </details>
  );
}

export function Button({ children }: PropsWithChildren) {
  return (
    <summary className="tw-btn tw-btn-neutral tw-border tw-border-solid tw-border-base-300 hover:tw-border-base-300">
      {children}
    </summary>
  );
}

export function List({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <ul
      className={cn(
        'tw-p-2 tw-shadow tw-menu tw-menu-xs tw-menu-vertical tw-dropdown-content tw-bg-base-100 tw-rounded-box tw-w-80',
        'tw-border tw-border-solid tw-border-base-300 tw-bg-white',
        'tw-z-[900]',
        className
      )}
    >
      {children}
    </ul>
  );
}
