import { useQuery } from '@tanstack/react-query';

import * as Api from '../../api/common';
import { getCurrentHarvestYear } from '../../configuration/seasons';
import { times } from '../times';

export type Sensor = Api.HydraResponse<{
  id: string;
  coordinates?: Api.Coordinates;
  harvestYear?: number;
  stationsNumber?: number;
  startOfHarvestWeek?: number;
}>;

export function useSensors() {
  const harvestYear = getCurrentHarvestYear();
  return useQuery({
    queryKey: ['sensors', { harvestYear }],
    queryFn: async () => fetchSensors(harvestYear?.toString() ?? undefined),
    staleTime: times.infinity,
    gcTime: times.infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnMount: false,
  });
}

async function fetchSensors(harvestYear?: string) {
  return (await Api.get(`/sensors`, {
    harvestYear,
  })) as Promise<Api.HydraCollection<Sensor>>;
}
