import React from 'react';
import { Form } from 'react-bootstrap';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { default as AsyncSelect } from 'react-select/async';

import { Town } from '../../../../api/common';
import { getCityLabel, searchCity } from '../../../../api/city';
import Label from '../Label';
import classNames from 'classnames';

export type Option = {
  label: string;
  value: Town;
};

type TownInputProps = FieldRenderProps<Town>;

export function TownInput({
  label,
  required,
  input,
  meta,
  placeholder,
}: TownInputProps) {
  const { t } = useTranslation();

  async function loadOptions(inputValue: string) {
    if (!inputValue || inputValue.length < 3) {
      return [];
    }

    const data = await searchCity(inputValue);
    return data['hydra:member'].map((town: Town) => ({
      value: town,
      label: getCityLabel(town),
    }));
  }

  const showError = (meta.touched && meta.error) || meta.submitError;
  const error = meta.error ?? meta.submitError;

  return (
    <Form.Group>
      <Label label={label} required={required} />
      <AsyncSelect
        noOptionsMessage={() => t('form.zipcode_input.no_options_message')}
        cacheOptions
        name={input.name}
        value={input.value}
        placeholder={placeholder}
        loadOptions={loadOptions}
        defaultOptions
        aria-invalid={error}
        onChange={input.onChange}
        onBlur={input.onBlur}
        classNames={{
          control: ({ className }) =>
            classNames(className, {
              '!tw-border-error': error,
            }),
        }}
      />
      {showError && (
        <Form.Control.Feedback
          className="invalid-feedback d-block"
          type="invalid"
        >
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
