const validate = (values, props) => {
  let { t } = props;
  t ??= (key) => key;

  const errors = {};
  if (!values.place) {
    errors.place = t('form.common.validation.required');
  }
  if (!values.cultivationMethod) {
    errors.cultivationMethod = t('form.common.validation.required');
  }
  if (!values.yield) {
    errors.yield = t('form.common.validation.required');
  }
  if (!values.variety) {
    errors.variety = t('form.common.validation.required');
  }
  if (!values.yieldNotation || values.yieldNotation < 0) {
    errors.yieldNotation = t('form.common.validation.required');
  }
  return errors;
};

export default validate;
