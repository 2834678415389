import { Record } from 'immutable';

type ToastProps = {
  title: null | string;
  body: null | string;
  variant: 'warning' | 'danger' | 'success' | 'info' | null;
};

export const Toast = Record<ToastProps>(
  {
    title: null,
    body: null,
    variant: null,
  },
  'Toast'
);

export default Toast;
