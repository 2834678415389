import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { removeToastAction } from '../../../../actions/ui/toast';
import { FaTimes } from 'react-icons/fa';
import { FaSmile } from 'react-icons/fa';
import { FaFrown } from 'react-icons/fa';

import styles from './styles.module.scss';

const Toast = ({ toast }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(removeToastAction(toast));
  };

  return (
    <div className={styles.toast}>
      <div className={styles.header}>
        <span className={styles.closeButton} onClick={handleClose}>
          <FaTimes />
        </span>
        {toast.variant === 'success' && (
          <div className={cx(styles.iconWrapper, styles.success)}>
            <FaSmile />
          </div>
        )}
        {toast.variant === 'danger' && (
          <div className={cx(styles.iconWrapper, styles.danger)}>
            <FaFrown />
          </div>
        )}
        <strong>{toast.title}</strong>
      </div>
      <div className={styles.body}>{toast.body}</div>
    </div>
  );
};

export default Toast;
