import React, { Component } from 'react';
import { connect } from 'react-redux';

import MediaImageInput from './index';

import { createToastAction } from '../../../../actions/ui/toast';
import Toast from '../../../../models/toast';
import { change } from 'redux-form';
import MediaObject from '../../../../models/mediaObject';
import { withTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

// TODO: migrate to react-final-form

class MediaImageInputContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      mediaObject: new MediaObject(),
    };
  }

  handleChange = async (e) => {
    console.debug(e.target.files[0]);
    const { dispatch, formName, input } = this.props;

    const file = e.target.files[0] ?? null;
    if (file?.size > 10000000) {
      dispatch(
        createToastAction(
          new Toast({
            title: 'Oops',
            body: "La taille de l'image ne doit pas dépasser 10 Mo",
            variant: 'danger',
          })
        )
      );
      return;
    }

    this.setState({ uploading: true });

    let formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${apiUrl}/media-objects`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
      },
      body: formData,
    });

    console.debug(response);

    if (!response.ok) {
      await this.handleError(response);
      return;
    }

    const result = await response.json();
    dispatch(change(formName, input.name, `/media-objects/${result.id}`));

    this.setState({
      mediaObject: new MediaObject(result),
      uploading: false,
    });
  };

  handleRemove = () => {
    this.setState({
      mediaObject: new MediaObject(),
      id: null,
    });
  };

  handleError = async (response) => {
    const { dispatch, formName, input, t } = this.props;
    console.error('[MediaObject] Upload failed', response);

    const error = await response.json();

    const errorMessage =
      error.violations && error.violations[0].propertyPath === 'file'
        ? error.violations[0].message
        : t('form.media_image_input.error.generic');

    dispatch(change(formName, input.name, null));
    this.setState({ uploading: false, mediaObject: new MediaObject() });
    dispatch(
      createToastAction(
        new Toast({
          title: 'Oops',
          body: errorMessage,
          variant: 'danger',
        })
      )
    );
  };

  render() {
    const {
      props,
      state: { mediaObject, uploading },
    } = this;

    return (
      <MediaImageInput
        input={props.input}
        id={props.id}
        label={props.label}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
        props={props}
        required={props.required}
        meta={props.meta}
        feedback={props.feedback}
        filePath={mediaObject.contentUrl}
        uploading={uploading}
        name={props.name}
      />
    );
  }
}

export default withTranslation()(connect()(MediaImageInputContainer));
