import { useQuery } from '@tanstack/react-query';

import * as Api from '../../api/common';
import { Coordinates, HydraCollection, HydraResponse } from '../../api/common';
import { times } from '../times';
import { CountryCode } from '../../config';
import { useConfig } from '../config';

export type Partner = HydraResponse<{
  id: string;
  name?: string;
  description?: string;
  websiteUrl?: string;
  coordinates?: Coordinates;
  logoUrl?: string;
}>;

export function usePartners() {
  return useQuery({
    queryKey: ['partners'],
    queryFn: async () => fetchPartners(),
    staleTime: times.infinity,
    gcTime: times.infinity,
  });
}

async function fetchPartners() {
  return (await Api.get(`/partners`)) as Promise<HydraCollection<Partner>>;
}

type FaqSection = HydraResponse<{
  id: string;
  title?: string;
  content?: string;
}>;

export function useFaq() {
  const { country } = useConfig();

  return useQuery({
    queryKey: ['faq', country],
    queryFn: async () => fetchFaq(country),
    staleTime: times.infinity,
    gcTime: times.infinity,
  });
}

async function fetchFaq(
  countryCode: CountryCode
): Promise<HydraCollection<FaqSection>> {
  return (await Api.get(`/faq`, {
    country: countryCode,
  })) as Promise<HydraCollection<Partner>>;
}
