import set from 'lodash/set';

import { API_URL } from './constant';
import { getCountryCode } from '../config';
import { getCurrentHarvestYear } from '../configuration/seasons';

type Params = Record<string, any>;
type Body = Record<string, any>;

export async function get(
  endpoint: string,
  params: Params = {},
  options: RequestInit = {}
): Promise<unknown> {
  const url = new URL(`${API_URL}${endpoint}`);
  // Adds the country code if it's not already set
  params.country ??= getCountryCode();
  params.year ??= getCurrentHarvestYear();

  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      url.searchParams.append(key, params[key].toString());
    }
  }

  const headers = {
    Accept: 'application/ld+json',
  };

  const response = await fetch(url, {
    ...options,
    credentials: 'omit', // TODO add a way to include credentials for authed requessts
    headers: {
      ...headers,
      ...(options.headers || {}),
    },
    method: 'GET',
  });

  return handleResponse(response);
}

export async function post(
  endpoint: string,
  body: Body = {},
  options: RequestInit = {}
): Promise<unknown> {
  const url = new URL(`${API_URL}${endpoint}`);

  const headers = {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  };

  // Sets the country code in the body so that we can filter on it later on
  body.country ??= getCountryCode();

  // Automatically injects UTM parameters
  const utms = getUtms();
  body = {
    ...utms,
    ...body,
  };

  const response = await fetch(url, {
    ...options,
    credentials: options.credentials ?? 'include',
    headers: {
      ...headers,
      ...(options.headers || {}),
    },
    method: 'POST',
    body: JSON.stringify(body),
  });

  return handleResponse(response);
}

function getUtms() {
  const params = new URLSearchParams(window.location.search);
  return {
    utmSource: params.get('utm_source') ?? params.get('utmSource') ?? undefined,
    utmMedium: params.get('utm_medium') ?? params.get('utmMedium') ?? undefined,
    utmCampaign:
      params.get('utm_campaign') ?? params.get('utmCampaign') ?? undefined,
    utmTerm: params.get('utm_term') ?? params.get('utmTerm') ?? undefined,
    utmContent:
      params.get('utm_content') ?? params.get('utmContent') ?? undefined,
  };
}

async function handleResponse(response: Response) {
  if (response.status === 422) {
    const json = await response.json();
    throw new UnprocessableEntityError(json);
  }

  if (response.status === 400) {
    const json = await response.json();
    throw new ClientError(json);
  }

  return response.json();
}

export type Iri = string;

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type Nation = {
  code: 'fr' | 'uk';
  name: string;
  slug: string;
};

export type Region = {
  code: string;
  name: string;
  slug: string;
};

export type Department = {
  code: string;
  name: string;
  slug: string;
};

export type Town = HydraElement<{
  id: string;
  name?: string;
  slug?: string;
  postalCode?: string;
  coordinates: HydraElement<Coordinates>;
  nation: HydraElement<Nation>;
  region: HydraElement<Region>;
  department: HydraElement<Department>;
}>;

export type HydraResponse<T> = {
  '@context': string;
  '@id': string;
  '@type': string;
} & T;

export type HydraElement<T> = {
  '@id': string;
  '@type': string;
} & T;

export type HydraCollection<T> = {
  '@context': string;
  '@id': string;
  '@type': 'hydra:Collection';
  'hydra:totalItems': number;
  'hydra:member': T[];
};

/**
 * This error type should be thrown on 422 errors, e.g.g when a form is invalid.
 */
export class UnprocessableEntityError extends Error {
  private readonly _json: HydraUnprocessableEntityError;

  constructor(json: HydraUnprocessableEntityError) {
    super(json['hydra:description']);
    this._json = json;
    this.name = json['@type'];
  }

  toFinalFormErrors() {
    const errors: Record<string, string> = {};

    this.json.violations.forEach((violation) => {
      const path = violation.propertyPath.split('.');
      set(errors, path, violation.message);
    });

    return errors;
  }

  get json() {
    return this._json;
  }
}

export class ClientError extends Error {
  private readonly _json: HydraError;

  constructor(json: HydraError) {
    super(json['hydra:description']);
    this._json = json;
    this.name = json['@type'];
  }

  get json() {
    return this._json;
  }
}

type HydraError<T = {}> = {
  '@context': string;
  '@type': string;
  'hydra:title': string;
  'hydra:description': string;
} & T;

type HydraUnprocessableEntityError = HydraError<{
  violations: Violation[];
}>;

type Violation = {
  propertyPath: string;
  message: string;
  code: string;
};
