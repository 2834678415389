import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { closeObservationDetailsModalAction } from '../../actions/ui/modal/observation/details';
import { closePartnerDetailsModalAction } from '../../actions/ui/modal/partner/details';
import { closeSensorDetailsModalAction } from '../../actions/ui/modal/sensor/details';
import { openModalAction } from '../../actions/ui/modal/observation/creation';
import { MapButton } from '../buttons';

export function AddObservationButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(closeObservationDetailsModalAction());
    dispatch(closePartnerDetailsModalAction());
    dispatch(closeSensorDetailsModalAction());
    dispatch(openModalAction());
  };

  return (
    <MapButton
      variant="accent"
      size="lg"
      onClick={handleClick}
      title={t('partager_mes_rendements.button.title')}
      shouldScale={true}
    >
      <span className="tw-uppercase tw-bold tw-text-xl md:tw-text-3xl tw-text-white">
        {t('partager_mes_rendements.button.label')}
      </span>
    </MapButton>
  );
}
