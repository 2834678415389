import React from 'react';
import { Field, reduxForm } from 'redux-form';
import NavigationBar from '../../NavigationBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionTitle from '../../SectionTitle';
import styles from './styles.module.scss';
import validate from './validate';
import TextareaInput from '../../../../common/form/TextareaInput';
import MediaImageInputContainer from '../../../../common/form/MediaImageInput/MediaImageInputContainer';
import { useTranslation } from 'react-i18next';

const ExtraForm = ({ handleSubmit, previousStep, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <section>
      <SectionTitle title="Extra" />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Row>
          <Col xs={12} md={6}>
            <Field
              name="image"
              component={MediaImageInputContainer}
              formName="observation"
              label={t('modale_creation_observation.extra.image_label')}
              feedback={t('modale_creation_observation.extra.image_feedback')}
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              name="comment"
              type="textarea"
              rows="6"
              component={TextareaInput}
              label={t('modale_creation_observation.extra.comment_label')}
            />
          </Col>
        </Row>
        <div className={styles.navigationWrapper}>
          <NavigationBar
            previousStep={previousStep}
            shouldSubmit={true}
            canSkip={true}
            submitLabel={t('common.next')}
          />
        </div>
      </form>
    </section>
  );
};

export default reduxForm({
  form: 'observation',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(ExtraForm);
