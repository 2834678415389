import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Button from 'react-bootstrap/Button';

import FieldInput from '../../../common/form/FieldInput';
import { useLocation } from '../../../../modules/location/useLocation';
import { usePlace } from '../../../../modules/place/usePlace';
import {
  createContact,
  useAuthentication,
  useEmail,
} from '../../../../modules/contacts';
import { TownInput } from '../../../common/form/future';
import { Option } from '../../../common/form/future/TownInput';
import { closePartnerDetailsModalAction } from '../../../../actions/ui/modal/partner/details';
import { closeSensorDetailsModalAction } from '../../../../actions/ui/modal/sensor/details';
import { openModalAction } from '../../../../actions/ui/modal/observation/creation';
import { closeObservationDetailsModalAction } from '../../../../actions/ui/modal/observation/details';
import { useConfig } from '../../../../modules/config';
import LatLng from '../../../../models/latLng';

import styles from './styles.module.scss';
import { setAskedPositionAction } from '../../../../actions/askedPosition';
import { UnprocessableEntityError } from '../../../../api/common';
import { useQueryClient } from '@tanstack/react-query';
import * as Modal from '../../../modals';

type Errors = Partial<Record<keyof Values, string>>;

type Values = {
  email: string;
  town: Option;
};

type ContactFormProps = {
  onSubmitSuccess?: () => void;
};

// TODO: [REFACTO] Remove anys from this file
// TODO: [REFACTO] Clean up this file, switch it to the new daisyUI based style and move to modules/contacts
export function ContactForm({ onSubmitSuccess }: ContactFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeLocation } = useLocation();
  const { changePlace } = usePlace();
  const { changeEmail } = useEmail();
  const { tos } = useConfig();
  const { authenticate } = useAuthentication();
  const queryClient = useQueryClient();
  const { close, openNextInQueue, clearQueue } = Modal.useModal();

  function onPlaceChange(option: Option) {
    const town = option.value;

    changePlace(town);
    changeLocation(
      new LatLng({
        // @ts-ignore
        longitude: town.coordinates.longitude,
        // @ts-ignore
        latitude: town.coordinates.latitude,
      })
    );
  }

  function onEmailChange(email: null | string) {
    changeEmail(email);
  }

  function onAddObservationClick() {
    close(Modal.Id.CreationContact);
    clearQueue();
    dispatch(closeObservationDetailsModalAction());
    dispatch(closePartnerDetailsModalAction());
    dispatch(closeSensorDetailsModalAction());
    dispatch(openModalAction());
  }

  async function onSubmit({ email, town: townOption }: Values) {
    const { value: town, label: place } = townOption ?? {};
    const { coordinates } = town ?? {};

    try {
      await createContact({
        email,
        place,
        coordinates,
        town: town ? town['@id'] : undefined,
      });

      if (coordinates) {
        console.info('[App] Setting position', coordinates);
        // @ts-ignore
        dispatch(setAskedPositionAction(new LatLng(coordinates)));
      } else {
        console.warn('No coordinates found for town', town);
      }

      // TODO: refactor -> use a route to check auth with cookies
      authenticate();

      onSubmitSuccess?.();
      close(Modal.Id.CreationContact);
      openNextInQueue();

      queryClient.invalidateQueries({ queryKey: ['observations'] });
      queryClient.invalidateQueries({ queryKey: ['rankings'] });
    } catch (error) {
      if (error instanceof UnprocessableEntityError) {
        console.error(
          'Form error while creating contact',
          error.toFinalFormErrors()
        );
        return error.toFinalFormErrors();
      }

      console.error('Unknwon error while creating contact', error);
      throw error;
    }
  }

  const validate = useCallback(
    (values: Values) => validateWith(t)(values),
    [t]
  );

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit }) => (
        <form name="contact" onSubmit={handleSubmit} className={styles.form}>
          <Row>
            <Col xs={12} md={6}>
              <Field
                name="town"
                type="select"
                label={t('form.zipcode_input.label')}
                formName="contact"
                placeholder={t('form.zipcode_input.placeholder')}
                required
                onChange={onPlaceChange}
                component={TownInput}
              />
            </Col>
            <Col xs={12} md={6}>
              <Field
                name="email"
                type="email"
                autoComplete="email"
                component={FieldInput}
                label={t('form.email_input.label')}
                required
                onChange={onEmailChange}
              />
            </Col>
            <Col xs={12}>
              <Alert variant="info">
                {t('modale_contact.notice_donnees_personnelles')}
              </Alert>
            </Col>
          </Row>

          <p className="tw-my-2">
            <Trans i18nKey="modale_contact.tos" t={t}>
              En cliquant sur Partagez vos rendements ou Validez vos coordonées,
              vous acceptez nos
              <a
                href={tos.url}
                target="_blank"
                rel="noreferrer"
                className="tw-text-primary"
              >
                {t('modale_contact.tos_link')}
              </a>
              .
            </Trans>
          </p>

          <div className={styles.buttonsWrapper}>
            <Button
              size="lg"
              variant="success"
              type="submit"
              className={styles.button}
            >
              {t('modale_contact.bouton_validez_vos_coordonnees.label')}
            </Button>
            <span className={styles.buttonsText}>Ou</span>
            <Button
              size="lg"
              variant="warning"
              type="button"
              onClick={onAddObservationClick}
              className={styles.button}
            >
              {t('modale_contact.bouton_partagez_vos_rendements.label')}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
}

function validateWith(t: TFunction) {
  return (values: Values) => {
    const errors: Errors = {};

    if (!values.town) {
      errors.town = t('form.common.validation.required') || undefined;
    }

    if (!values.email) {
      errors.email = t('form.common.validation.required') || undefined;
    }

    return errors;
  };
}
