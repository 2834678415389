import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Modal from '../../components/modals';
import { MapButton } from '../../components/buttons';
import cn from 'classnames';
import { useConfig } from '../config';

export function CadeauButton() {
  const { t } = useTranslation();
  const config = useConfig();
  const { open } = Modal.useModal();

  if (!config.concours.enabled) {
    return null;
  }

  return (
    <MapButton
      className={cn(
        'tw-flex tw-items-center tw-justify-center',
        'animated-background tw-bg-gradient-to-l tw-from-green-200 tw-via-yellow-200 tw-to-orange-200'
      )}
      onClick={() => open(Modal.Id.Cadeau)}
      size="md"
      title={t('cadeau_modale.bouton')}
    >
      <span className="tw-text-md">🎉</span>
      <span className="tw-hidden lg:tw-inline">
        {t('cadeau_modale.bouton')}
      </span>
    </MapButton>
  );
}

export function CadeauModal() {
  const { t } = useTranslation();
  const config = useConfig();
  return (
    <Modal.Dialog id={Modal.Id.Cadeau}>
      <Modal.Box className="tw-max-w-3xl">
        <form method="dialog">
          <Modal.CloseButton />
        </form>
        <h3 className="tw-font-bold tw-text-lg tw-text-center tw-w-full">
          {t('cadeau_modale.titre')}
        </h3>

        <div className="tw-p-4 tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center">
          <img
            className="tw-max-h-[300px] tw-rounded-lg tw-shadow-xl tw-float-start"
            src="https://res.cloudinary.com/comparateuragricole/image/upload/q_auto:eco,f_auto/v1721329376/strapi/Raincrop_top_59a98367c5.png"
            alt="Station Raincrop de Sendcrop"
          />

          <div className="tw-flex tw-flex-col tw-items-center">
            <p className="tw-text-center">
              <Trans i18nKey={'cadeau_modale.description'} />
            </p>

            <a
              href={config.concours.reglementUrl}
              download={true}
              className="tw-text-center"
            >
              {t('cadeau_modale.reglement')}
            </a>
          </div>
        </div>
      </Modal.Box>
      <Modal.Overlay />
    </Modal.Dialog>
  );
}
