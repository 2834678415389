import * as API from '../../api/common';

export type CreateContactRequest = Partial<{
  email: string;
  place: string;
  coordinates: API.Coordinates;
  town: API.Iri;
}>;

export type Contact = {
  place: string;
  coordinates: API.Coordinates;
  town: API.Town;
};

export async function createContact(contact: CreateContactRequest) {
  return (await API.post('/contacts', contact, {
    credentials: 'include',
  })) as Promise<API.HydraResponse<Contact>>;
}
