import { useCookies } from 'react-cookie';

export function useLocation() {
  const [cookies, setCookie] = useCookies(['location']);

  function changeLocation(newLocation) {
    setCookie('location', newLocation);
  }

  function getLocation() {
    return cookies['location'];
  }

  /**
   * @see https://www.geodatasource.com/developers/javascript
   */
  function getDistance(otherLocation) {
    const location = getLocation();

    if (!location) {
      return null;
    }

    if (!location.latitude && !location.longitude) {
      return null;
    }

    const lat1 = location.latitude;
    const lon1 = location.longitude;
    const lat2 = otherLocation.latitude;
    const lon2 = otherLocation.longitude;

    const radLat1 = (Math.PI * lat1) / 180;
    const radLat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radTheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radLat1) * Math.sin(radLat2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);

    if (dist > 1) {
      dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;

    return dist;
  }

  return {
    changeLocation,
    getLocation,
    getDistance,
  };
}
