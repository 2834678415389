import { useEffect } from 'react';

type HashMatcherProps = {
  hash: string;
  onMatch: () => void;
};

export function HashMatcher({ hash, onMatch }: HashMatcherProps) {
  useEffect(
    () => {
      if (window.location.hash === hash) {
        console.debug('[HashMatcher] Hash matched', hash);
        onMatch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // the effect should only run once
  );
  return null;
}
