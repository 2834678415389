import React, { lazy, Suspense } from 'react';
import { Pane } from 'react-leaflet';

import { PaneName, ZIndex } from '../../config';

const Layer = lazy(() => import('./map.lazy'));

export function PrevisionsPane() {
  return (
    <Pane name={PaneName.Previsions} style={{ zIndex: ZIndex.Previsions }}>
      <Suspense>
        <Layer layer="rendement_ble_tendre" />
        <Layer layer="rendement_colza" />
        <Layer layer="rendement_orge_hiver" />
        <Layer layer="rendement_orge_printemps" />
        <Layer layer="evolution_rendement_ble_tendre" />
        <Layer layer="evolution_rendement_colza" />
        <Layer layer="evolution_rendement_orge_hiver" />
        <Layer layer="evolution_rendement_orge_printemps" />
        <Layer layer="poids_specifique_orge_hiver" />
        <Layer layer="poids_specifique_ble_tendre" />
        <Layer layer="proteine_ble_tendre" />
      </Suspense>
    </Pane>
  );
}
