import { getCountryCode } from '../config';

const libPhone = await import('google-libphonenumber');
const phoneUtil = libPhone.PhoneNumberUtil.getInstance();
const PNF = libPhone.PhoneNumberFormat;

const region = getCountryCode() === 'uk' ? 'GB' : 'FR';

/**
 * Format a given phone number to E164 format.
 * E164 is an international phone format: https://en.wikipedia.org/wiki/E.164
 *
 * @param number
 * @returns string
 */
export function parseAndFormat(number) {
  const phone = phoneUtil.parse(number, region);
  return phoneUtil.format(phone, PNF.E164);
}

/**
 * Validate that a given number can be parsed and is valid.
 *
 * @param number
 * @returns boolean
 */
export function isValidNumber(number) {
  try {
    const phone = phoneUtil.parse(number, region);
    return phoneUtil.isPossibleNumber(phone);
  } catch {
    return false;
  }
}
