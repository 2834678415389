import React from 'react';
import Partner from '../Partner';
import { usePartners } from '../../../modules/partners';

const PartnersMarkerList = () => {
  const { data } = usePartners();

  if (!data) {
    return null;
  }

  const partners = data['hydra:member'] ?? [];

  return partners.map((partner) => {
    return <Partner partner={partner} key={partner.id} />;
  });
};

export default PartnersMarkerList;
