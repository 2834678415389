import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import cx from 'classnames';
import Table from 'react-bootstrap/Table';
import {
  getSelectedSensor,
  isSensorDetailsModalOpen,
} from '../../../selectors/ui/modal/sensor/details';
import { closeSensorDetailsModalAction } from '../../../actions/ui/modal/sensor/details';

import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useConfig } from '../../../modules/config';

export function SensorDetailsModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sensors } = useConfig();
  const sensor = useSelector((state) => getSelectedSensor(state));
  const isOpen = useSelector((state) => isSensorDetailsModalOpen(state));

  const handleClose = () => {
    dispatch(closeSensorDetailsModalAction());
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h5 className={cx(styles.header)}>
        <span className={styles.closeButton} onClick={handleClose}>
          <FaTimes />
        </span>
        {t('modale_weather_station.title')}
      </h5>
      <div className={styles.tableWrapper}>
        <Table striped bordered hover className={styles.table}>
          <tbody>
            <tr>
              <td>
                <Trans
                  i18nKey="modale_weather_station.text"
                  values={{ count: sensor.stationsNumber }}
                >
                  <p>
                    Consultez les conditions et prévisions ultra locales pour
                    votre top départ moisson en utilisant une des
                    <strong>
                      {{ count: sensor.stationsNumber }} stations météo
                    </strong>{' '}
                    Sencrop proche de vos parcelles.
                  </p>
                </Trans>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  href={sensors.url}
                  className={cx(styles.button, 'btn btn-primary btn-block')}
                  target="blank"
                >
                  {t('modale_weather_station.link')}
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}
