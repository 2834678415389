import React, { useState } from 'react';
import { useMap } from 'react-leaflet';
import { FaLocationArrow } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { MapButton } from '../index';

type Permission = 'granted' | 'denied';

export function GeolocateButton() {
  const { t } = useTranslation();
  const [permission, setPermission] = useState<Permission | null>(null);

  const map = useMap();

  const onClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        acceptGeolocation,
        denyGeolocation
      );
    }
  };

  const acceptGeolocation: PositionCallback = (position) => {
    setPermission('granted');
    panTo(position);
  };

  const denyGeolocation: PositionErrorCallback = () => {
    setPermission('denied');
  };

  function panTo(position: GeolocationPosition) {
    // A quick and dirty fix to enforce a new position every time user require to recenter map on same position
    const randomFloat = parseFloat(
      (Math.random() * (0.0002 - 0.0001) + 0.0001).toFixed(6)
    );

    map.flyTo(
      [
        position.coords.latitude + randomFloat,
        position.coords.longitude + randomFloat,
      ],
      10
    );
  }

  if (permission === 'denied') {
    return null;
  }

  return (
    <MapButton
      onClick={onClick}
      title={t('carte.geolocaliser_ma_position.title')}
    >
      <FaLocationArrow />
    </MapButton>
  );
}
