import React, { useState } from 'react';

export const ModalContext = React.createContext();

export function useModal() {
  return React.useContext(ModalContext);
}

export const ModalProvider = ({ children }) => {
  const [currentModal, setCurrentModal] = useState('');

  const modal = {
    currentModal,
    setCurrentModal,
  };

  return (
    <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
  );
};
