import React from 'react';
import { Form } from 'react-bootstrap';
import { default as AsyncSelect } from 'react-select/async';
import { useTranslation } from 'react-i18next';

import Label from '../Label';

const TownInput = ({
  input,
  meta,
  label,
  value,
  onBlur,
  loadOptions,
  handleChange,
  placeholder,
  required,
  noOptionsMessage,
  ...props
}) => {
  const { t } = useTranslation();

  const showError = (meta.touched && meta.error) || meta.submitError;
  const error = meta.error ?? meta.submitError;
  return (
    <Form.Group>
      <Label label={label} required={required} />
      <AsyncSelect
        noOptionsMessage={() => t('form.zipcode_input.no_options_message')}
        cacheOptions
        value={value}
        loadOptions={loadOptions}
        defaultOptions
        onBlur={onBlur}
        onChange={handleChange}
        placeholder={placeholder}
        {...props}
      />
      {showError && (
        <Form.Control.Feedback
          className="invalid-feedback d-block"
          type="invalid"
        >
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default TownInput;
