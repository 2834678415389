import { delay, put, takeEvery } from 'redux-saga/effects';

import {
  addToastAction,
  createObservationToastAction,
  createToastAction,
  removeToastAction,
} from '../../actions/ui/toast';
import ObservationToast from '../../models/ui/observationToast';
import { getCountryCode } from '../../config';

export function* watchCreateObservationToast({ payload: observation }) {
  const toast = new ObservationToast({
    coordinates: observation.coordinates,
    message:
      getCountryCode() === 'uk'
        ? `New ${observation.cultureName} harvest in ${observation}`
        : `Nouvelle observation de ${observation.cultureName} à ${observation.place}`,
  });
  yield put(addToastAction(toast));
  yield delay(7000);
  yield put(removeToastAction(toast));
}

export function* watchCreateToast({ payload: toast }) {
  yield put(addToastAction(toast));
  yield delay(7000);
  yield put(removeToastAction(toast));
}

export const sagas = [
  takeEvery(createObservationToastAction, watchCreateObservationToast),
  takeEvery(createToastAction, watchCreateToast),
];
