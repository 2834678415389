import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Modal from '../../components/modals';
import { MapButton } from '../../components/buttons';
import { PiPlant } from 'react-icons/pi';

export function PrevisionsGlobalesButton() {
  const { t } = useTranslation();
  const { open } = Modal.useModal();

  return (
    <MapButton
      className="tw-flex tw-items-center tw-justify-center"
      onClick={() => open(Modal.Id.PrevisionsGlobales)}
      size="md"
    >
      <PiPlant className="tw-hidden lg:tw-block" />
      <span>{t('previsions_globales.bouton')}</span>
    </MapButton>
  );
}
