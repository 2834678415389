import React from 'react';
import { useTranslation } from 'react-i18next';

export const Spinner = () => {
  const { t } = useTranslation();
  return (
    <div className={'tw-flex tw-items-center tw-justify-center tw-gap-2'}>
      <span className="tw-loading tw-loading-spinner tw-text-primary"></span>
      <span className="tw-font-black">{t('common.spinner.text')}.</span>
    </div>
  );
};
