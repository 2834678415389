import { Layers } from '../layers';

export const palette = {
  fallback: '#808080',
  very_high: '#d73027',
  high: '#f46d43',
  somewhat_high: '#fdae61',
  above_average: '#fee090',
  average: '#ffffbf',
  below_average: '#e0f3f8',
  somewhat_low: '#abd9e9',
  low: '#74add1',
  very_low: '#4575b4',
};

type LigneEchelle =
  | {
      max: number;
      couleur: string;
      unite: string;
    }
  | {
      min: number;
      couleur: string;
      unite: string;
    }
  | {
      min: number;
      max: number;
      couleur: string;
      unite: string;
    };

export type Echelle = {
  afficherSigne: boolean;
  lignes: LigneEchelle[];
};

export function getEchelleForLayer(
  layer: Layers['previsions']
): null | Echelle {
  switch (layer) {
    case 'rendement_ble_tendre':
      return echelleRendementBleTendre;
    case 'rendement_orge_printemps':
      return echelleRendementOrgePrintemps;
    case 'rendement_orge_hiver':
      return echelleRendementOrgeHiver;
    case 'rendement_colza':
      return echelleRendementColza;

    case 'poids_specifique_ble_tendre':
      return echellePoidsSpecifiqueBleTendre;
    case 'poids_specifique_orge_hiver':
      return echellePoidsSpecifiqueOrgeHiver;

    case 'proteine_ble_tendre':
      return echelleProteineBleTendre;

    case 'evolution_rendement_ble_tendre':
    case 'evolution_rendement_colza':
    case 'evolution_rendement_orge_printemps':
    case 'evolution_rendement_orge_hiver':
      return echelleEvolutionRendement;
    default:
      return null;
  }
}

export function getColorForLayer(
  layer: Layers['previsions'],
  value: number | undefined | null
): string {
  if (value === undefined || value === null) {
    return palette.fallback;
  }

  const echelle = getEchelleForLayer(layer);

  const ligne = echelle?.lignes.find(function (e) {
    if ('max' in e && 'min' in e) {
      return e.min < value && value <= e.max;
    }

    if ('max' in e) {
      return value <= e.max;
    }

    return e.min < value;
  });

  return ligne?.couleur ?? palette.fallback;
}

const echelleEvolutionRendement = {
  afficherSigne: true,
  lignes: [
    { unite: '%', max: -45, couleur: palette.very_low },
    { unite: '%', min: -45, max: -30, couleur: palette.low },
    { unite: '%', min: -30, max: -15, couleur: palette.somewhat_low },
    { unite: '%', min: -15, max: -5, couleur: palette.below_average },
    { unite: '%', min: -5, max: 5, couleur: palette.average },
    { unite: '%', min: 5, max: 15, couleur: palette.above_average },
    { unite: '%', min: 15, max: 30, couleur: palette.somewhat_high },
    { unite: '%', min: 30, max: 45, couleur: palette.high },
    { unite: '%', min: 45, couleur: palette.very_high },
  ],
} satisfies Echelle;

const echelleRendementBleTendre = {
  afficherSigne: false,
  lignes: [
    { unite: 'qx/ha', max: 30, couleur: palette.very_low },
    { unite: 'qx/ha', min: 30, max: 38, couleur: palette.low },
    { unite: 'qx/ha', min: 38, max: 46, couleur: palette.somewhat_low },
    { unite: 'qx/ha', min: 46, max: 54, couleur: palette.below_average },
    { unite: 'qx/ha', min: 54, max: 62, couleur: palette.average },
    { unite: 'qx/ha', min: 60, max: 70, couleur: palette.above_average },
    { unite: 'qx/ha', min: 70, max: 78, couleur: palette.somewhat_high },
    { unite: 'qx/ha', min: 78, max: 86, couleur: palette.high },
    { unite: 'qx/ha', min: 86, couleur: palette.very_high },
  ],
} satisfies Echelle;

const echellePoidsSpecifiqueBleTendre = {
  afficherSigne: false,
  lignes: [
    { unite: 'kg/hl', max: 68, couleur: palette.very_low },
    { unite: 'kg/hl', min: 70, max: 72, couleur: palette.low },
    { unite: 'kg/hl', min: 72, max: 74, couleur: palette.somewhat_low },
    { unite: 'kg/hl', min: 74, max: 76, couleur: palette.below_average },
    { unite: 'kg/hl', min: 76, max: 78, couleur: palette.average },
    { unite: 'kg/hl', min: 78, max: 80, couleur: palette.above_average },
    { unite: 'kg/hl', min: 80, max: 82, couleur: palette.somewhat_high },
    { unite: 'kg/hl', min: 82, max: 84, couleur: palette.high },
    { unite: 'kg/hl', min: 84, couleur: palette.very_high },
  ],
} satisfies Echelle;

const echelleProteineBleTendre = {
  afficherSigne: false,
  lignes: [
    { unite: '%', max: 8, couleur: palette.very_low },
    { unite: '%', min: 8, max: 9, couleur: palette.low },
    { unite: '%', min: 9, max: 10, couleur: palette.somewhat_low },
    { unite: '%', min: 10, max: 11, couleur: palette.below_average },
    { unite: '%', min: 11, max: 12, couleur: palette.average },
    { unite: '%', min: 12, max: 13, couleur: palette.above_average },
    { unite: '%', min: 13, max: 14, couleur: palette.somewhat_high },
    { unite: '%', min: 14, max: 15, couleur: palette.high },
    { unite: '%', min: 15, couleur: palette.very_high },
  ],
} satisfies Echelle;

const echelleRendementColza = {
  afficherSigne: false,
  lignes: [
    { unite: 'qx/ha', max: 16, couleur: palette.very_low },
    { unite: 'qx/ha', min: 16, max: 19, couleur: palette.low },
    { unite: 'qx/ha', min: 19, max: 22, couleur: palette.somewhat_low },
    { unite: 'qx/ha', min: 22, max: 25, couleur: palette.below_average },
    { unite: 'qx/ha', min: 25, max: 28, couleur: palette.average },
    { unite: 'qx/ha', min: 28, max: 31, couleur: palette.above_average },
    { unite: 'qx/ha', min: 31, max: 34, couleur: palette.somewhat_high },
    { unite: 'qx/ha', min: 34, max: 37, couleur: palette.high },
    { unite: 'qx/ha', min: 37, couleur: palette.very_high },
  ],
} satisfies Echelle;

const echelleRendementOrgeHiver = {
  afficherSigne: false,
  lignes: [
    { unite: 'qx/ha', max: 38, couleur: palette.very_low },
    { unite: 'qx/ha', min: 38, max: 42, couleur: palette.low },
    { unite: 'qx/ha', min: 42, max: 46, couleur: palette.somewhat_low },
    { unite: 'qx/ha', min: 46, max: 50, couleur: palette.below_average },
    { unite: 'qx/ha', min: 50, max: 54, couleur: palette.average },
    { unite: 'qx/ha', min: 54, max: 58, couleur: palette.above_average },
    { unite: 'qx/ha', min: 58, max: 62, couleur: palette.somewhat_high },
    { unite: 'qx/ha', min: 62, max: 66, couleur: palette.high },
    { unite: 'qx/ha', min: 66, couleur: palette.very_high },
  ],
} satisfies Echelle;

const echelleRendementOrgePrintemps = {
  afficherSigne: false,
  lignes: [
    { unite: 'qx/ha', max: 20, couleur: palette.very_low },
    { unite: 'qx/ha', min: 20, max: 25, couleur: palette.low },
    { unite: 'qx/ha', min: 25, max: 30, couleur: palette.somewhat_low },
    { unite: 'qx/ha', min: 30, max: 35, couleur: palette.below_average },
    { unite: 'qx/ha', min: 35, max: 40, couleur: palette.average },
    { unite: 'qx/ha', min: 40, max: 45, couleur: palette.above_average },
    { unite: 'qx/ha', min: 45, max: 50, couleur: palette.somewhat_high },
    { unite: 'qx/ha', min: 50, max: 55, couleur: palette.high },
    { unite: 'qx/ha', min: 55, couleur: palette.very_high },
  ],
} satisfies Echelle;

const echellePoidsSpecifiqueOrgeHiver = {
  afficherSigne: false,
  lignes: [
    { unite: 'kg/hl', max: 47, couleur: palette.very_low },
    { unite: 'kg/hl', min: 47, max: 50, couleur: palette.low },
    { unite: 'kg/hl', min: 50, max: 53, couleur: palette.somewhat_low },
    { unite: 'kg/hl', min: 53, max: 56, couleur: palette.below_average },
    { unite: 'kg/hl', min: 56, max: 59, couleur: palette.average },
    { unite: 'kg/hl', min: 59, max: 62, couleur: palette.above_average },
    { unite: 'kg/hl', min: 62, max: 65, couleur: palette.somewhat_high },
    { unite: 'kg/hl', min: 65, max: 68, couleur: palette.high },
    { unite: 'kg/hl', min: 68, couleur: palette.very_high },
  ],
} satisfies Echelle;
