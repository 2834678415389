import { createAction } from 'redux-act';

export const openSensorDetailsModalAction = createAction(
  'ui/modal/sensor/details/open'
);
export const closeSensorDetailsModalAction = createAction(
  'ui/modal/sensor/details/close'
);
export const selectSensorAction = createAction(
  'ui/modal/sensor/details/sensor/select'
);
