import React from 'react';
import { Marker as LeafletMarker } from 'react-leaflet';
import type { Icon } from 'leaflet';

type MarkerProps = {
  latLng?: { latitude: number; longitude: number };
  icon: Icon;
  onClick?: () => void;
  zIndexOffset?: number;
  opacity?: number;
};

const Marker = ({
  latLng,
  icon,
  onClick,
  zIndexOffset = 0,
  opacity = 1,
}: MarkerProps) => {
  if (!latLng) {
    return null;
  }

  return (
    <LeafletMarker
      position={[latLng.latitude, latLng.longitude]}
      icon={icon}
      zIndexOffset={zIndexOffset}
      opacity={opacity}
      eventHandlers={{
        click: onClick,
      }}
    />
  );
};

export default Marker;
