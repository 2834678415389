import React from 'react';
import { useTranslation } from 'react-i18next';
import { GiFrance } from 'react-icons/gi';
import * as Modal from '../modals';
import * as Dropdown from '../../components/dropdown';
import { MapButton } from '../buttons';
import ukMap from '../../images/icons/uk-map.png';
import { useConfig } from '../../modules/config';

export function DepartmentsRankingButton() {
  const { t } = useTranslation();
  const { country } = useConfig();
  const { open } = Modal.useModal();

  return (
    <MapButton
      className="tw-flex tw-items-center tw-justify-center"
      onClick={() => open(Modal.Id.DepartmentsRanking)}
    >
      {country === 'fr' ? (
        <GiFrance className="tw-text-2xl" />
      ) : (
        <img src={ukMap} alt="" className="tw-grayscale tw-h-6 tw-w-4" />
      )}
      <span>{t('ranking_department.button.label')}</span>
    </MapButton>
  );
}

export function DepartmentsRankingMenuItem() {
  const { t } = useTranslation();
  const { country } = useConfig();
  const { open } = Modal.useModal();

  return (
    <Dropdown.Item onClick={() => open(Modal.Id.DepartmentsRanking)}>
      {country === 'fr' ? (
        <GiFrance className="tw-text-xl" />
      ) : (
        <img src={ukMap} alt="" className="tw-grayscale tw-h-5 tw-w-5" />
      )}
      <span>{t('ranking_department.button.label')}</span>
    </Dropdown.Item>
  );
}
