import * as Api from './common';
import { getCountryCode } from '../config';

/**
 *
 * @param search
 * @returns {Promise<Record<{contentUrl: null, id: null}> & Readonly<{contentUrl: null, id: null}>>}
 */
export async function searchCity(search) {
  if (!search) {
    return null;
  }

  return await Api.get('/v2-cities', {
    search,
    country: getCountryCode(),
  });
}

export function getCityLabel(city) {
  if (city.nation.code === 'fr') {
    return `${city.postalCode} ${city.name}`;
  } else {
    return `${city.name} - ${city.department.name} - ${city.region.name}`;
  }
}
