import React from 'react';
import { useDispatch } from 'react-redux';
import markerCereapro from '../../../images/partners/icon-cereapro.png';
import markerTerreNet from '../../../images/partners/icon-terre-net.png';
import markerSencrop from '../../../images/partners/icon-sencrop.png';
import markerFarmr from '../../../images/partners/icon-farmr.png';
import markerMaterielAgricole from '../../../images/partners/icon-materiel-agricole-info.png';
import markerGraindex from '../../../images/partners/icon-graindex.png';
import markerFarmingForum from '../../../images/partners/icon-the-farming-forum.png';
import Marker from '../../common/Marker';
import L from 'leaflet';
import {
  openPartnerDetailsModalAction,
  selectPartnerAction,
} from '../../../actions/ui/modal/partner/details';
import { closeObservationDetailsModalAction } from '../../../actions/ui/modal/observation/details';
import { closeSensorDetailsModalAction } from '../../../actions/ui/modal/sensor/details';
import { ZIndex } from '../../../config';

function getPartnerMarker(partnerName) {
  switch (partnerName) {
    case 'Cereapro':
      return markerCereapro;
    case 'Terre-Net':
      return markerTerreNet;
    case 'Sencrop':
      return markerSencrop;
    case 'Farmr':
      return markerFarmr;
    case 'MatérielAgricole.info':
      return markerMaterielAgricole;
    case 'Graindex':
      return markerGraindex;
    case 'The Farming Forum':
      return markerFarmingForum;
    default:
      return null;
  }
}

const Partner = ({ partner }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectPartnerAction(partner));
    dispatch(closeObservationDetailsModalAction());
    dispatch(closeSensorDetailsModalAction());
    dispatch(openPartnerDetailsModalAction());
  };

  const iconUrl = getPartnerMarker(partner.name);

  if (!iconUrl) {
    return null;
  }

  const icon = new L.Icon({
    iconUrl: iconUrl,
    iconSize: [60, 70.3],
  });

  return (
    <Marker
      icon={icon}
      onClick={handleClick}
      latLng={partner.coordinates}
      zIndexOffset={ZIndex.Partner}
    />
  );
};

export default Partner;
