import { createAction } from 'redux-act';

export const openPartnerDetailsModalAction = createAction(
  'ui/modal/partner/details/open'
);
export const closePartnerDetailsModalAction = createAction(
  'ui/modal/partner/details/close'
);
export const selectPartnerAction = createAction(
  'ui/modal/partner/details/partner/select'
);
