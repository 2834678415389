import i18n from 'i18next';

import fr from './locale/fr/translation.json';
import { getCountryCode } from './config';

const isDev = process.env.NODE_ENV === 'development';

i18n.init({
  debug: isDev,
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  resources: {
    fr: {
      translation: fr,
    },
    en: {
      translation: fr, // Re-add the en translations later if necessary
    },
  },

  fallbackLng: [getCountryCode() === 'uk' ? 'en' : 'fr'],
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export default i18n;
