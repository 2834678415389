import { isValidNumber } from '../../../../../utils/phoneUtil';

const validate = async (values, props) => {
  let { t } = props;
  t ??= (key) => key;

  const errors = {};
  if (!values.email) {
    errors.email = t('form.common.validation.required');
  }

  if (values.phone && !(await isValidNumber(values.phone))) {
    errors.phone = t('form.common.validation.invalid_phone');
  }

  return errors;
};

export default validate;
