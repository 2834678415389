import { useTranslation } from 'react-i18next';
import * as Modal from '../../components/modals';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCollection } from '../../strapi';
import classNames from 'classnames';

export function TimelineButton() {
  const { t } = useTranslation();
  const { data, status } = useDerniereActualite();
  const { open } = Modal.useModal();

  const derniereMaj = data?.attributes.createdAt
    ? new Date(data.attributes.createdAt)
    : undefined;

  return (
    <button
      onClick={() => open(Modal.Id.Timeline)}
      title={t('actualites.title') ?? ''}
      className={classNames(
        'btn btn-darken-primary',
        '!tw-flex !tw-items-center tw-gap-2 tw-h-10'
      )}
    >
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
        <Ping />

        <div className="tw-uppercase tw-font-bold">
          {t('actualites.nav_button')}
        </div>

        <div className="tw-text-xs tw-italic tw-hidden lg:tw-block">
          {status === 'success' &&
            derniereMaj &&
            t('actualites.last_maj', {
              val: derniereMaj,
              formatParams: {
                val: {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                },
              },
            })}

          {status === 'pending' && (
            <div className="tw-animate-pulse">
              <div className="tw-rounded tw-bg-slate-200 tw-opacity-50 tw-h-4 tw-w-[102px]"></div>
            </div>
          )}
        </div>
      </div>
    </button>
  );
}

function Ping() {
  return (
    <div className="tw-relative tw-flex tw-h-3 tw-w-3">
      <span className="tw-animate-ping tw-absolute tw-z-[9999] tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400 tw-opacity-75"></span>
      <span className="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-500"></span>
    </div>
  );
}

function useDerniereActualite() {
  const options = {
    pagination: {
      page: 1,
      pageSize: 1,
    },
    sort: 'createdAt:desc',
  };

  return useQuery({
    queryKey: ['strapi', 'moisson-live-actualites', options],
    queryFn: async ({ pageParam = 1 }) => {
      const response =
        await getCollection<'api::moisson-live-actualite.moisson-live-actualite'>(
          '/moisson-live-actualites',
          options
        );

      return response.data[0] ?? undefined;
    },
  });
}
