import { createReducer } from 'redux-act';

import {
  closeObservationDetailsModalAction,
  openObservationDetailsModalAction,
  selectObservationAction,
} from '../../../../actions/ui/modal/observation/details';
import ObservationDetailsModal from '../../../../models/ui/modal/observation/details';

export const REDUCER_KEY = 'ui/modal/observation/details';

const reducer = createReducer(
  {
    [openObservationDetailsModalAction]: (state) => {
      return state.setIn(['isOpen'], true);
    },
    [closeObservationDetailsModalAction]: (state) => {
      return state.setIn(['isOpen'], false);
    },
    [selectObservationAction]: (state, payload) => {
      return state.setIn(['observation'], payload);
    },
  },
  new ObservationDetailsModal()
);

export default {
  [REDUCER_KEY]: reducer,
};
